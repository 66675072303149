@import '../../assets/styles/branding';

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  overflow: hidden;
  border-radius: 27px;
  border: 1px solid #888;
  max-width: 80%;
  width: 420px;
  -webkit-box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.17);
  box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.17);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: $ds-text-light-color;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 27px 16px 19px;
  background-color: white;
  color: $ds-text-light-color;
  text-align: center;
}

.modal-body {
  padding: 30px 19px;
  display: flex;
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
  flex-direction: column;
}

.modal-footer {
  padding: 30px 19px;
  background-color: white;
  text-align: center;
  color: white;

  button {
    margin: 0 auto;
    width: 14rem;
  }
}

.modal-text-title {
  font-size: 1.8rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #1f1f1f;
}

.modal-text-content {
  font-size: 1.4rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0.4px;
  color: #505050;
}

.modal-btn-accent {
  display: block;
  width: 25rem;
  height: 4.8rem;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  border-radius: 11px;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.3px;
  background-color: $app-accent-color;
  color: $ds-text-light-color;
}
