.side-menu {
  z-index: 2;
  top: 60px;
  transform: none;
  visibility: visible;
  box-shadow: 3px 0 6px rgba(0,0,0,.24);
  padding-bottom: 72px;
  bottom: 0;
  position: fixed;
  width: 325px;
  height: 100%;
  background-color: white;

  @media (max-width: 730px) {
    overflow: hidden;
  }

  @media (max-width: 324px) {
    width: 100%;
  }

  h6 {
    text-transform: uppercase;
    font-weight: lighter;
    font-size: 14px;
    color: #bdbdbd;
    margin: 35px 0 10px 52px;
  }

  .side-menu-options {
    width: 100%;
    height: 100%;
    background-color: red;
    overflow-x: hidden;
    overflow-y: auto;
  }
}


.side-menu-item {
  width: 324px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #939393;
  font-weight: 700;
  height: 50px;

  &:active {
    .side-menu-item-wrapper {
      color: #6297b1;
      font-weight: 700;
    }
  }

  &:hover {
    background-color: #E8E8E8;
  }

  .side-menu-item-wrapper {
    display: flex;
    flex-direction: row;
    padding: 15px 15px 15px 52px;
    font-size: 1.4rem;
    width: 324px;
    letter-spacing: 1.2px;
    color: #939393;
    font-weight: 400;
    align-items: center;

    @media (max-width: 500px) {
      font-size: 15px;
    }

    .side-menu-item-icon {
      margin-right: 20px;

      i {
        font-size: 24px;
      }
    }

    .side-menu-item-name {
      letter-spacing: 0.5px;
    }
  }
}

.side-menu-item-selected {
  .side-menu-item-wrapper {
    color: #6297b1;
    font-weight: 700;
  }
}

.side-menu-powered-by {
  position: absolute;
  left: 53px;
  bottom: 50px;
  border-top: 1px solid rgba(135, 135, 135, 0.1);
  width: 245px;
  padding-top: 22px;
  padding-bottom: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-height: 680px) {
    position: relative;
  }

  p {
    display: inline-block;
    letter-spacing: 0.5px;
    color: #bdbdbd;
    font-size: 12px;
  }

  img {
    width: 32px;
    height: 32px;
    display: inline-block;
    margin-right: 20px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  min-height: 32px;
  min-width: 280px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 6px;
  padding: 8px 16px;
  position: absolute;
  z-index: 1;
  top: 35px;
  transform: translateY(-50%);
  left: 105%;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  text-align: left;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.5) transparent transparent;
}
.tooltip:hover .tooltiptext {
  visibility: hidden;

  @media (min-width: 901px) {
    visibility: visible;
  }
}
