@import "../../assets/styles/branding";

.password-strength {
  margin-top: 8px;
  z-index: 0;

  &-message {
    font-family: $font-auth-standard;
    font-size: 1.4rem;
    text-align: center;
    line-height: 1.6rem;
    letter-spacing: -0.12px;
    font-weight: 400;
    max-width: 196px;
    margin: 7px auto 0;
  }

  &-text {
    font-family: $font-auth-standard;
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.6rem;
    letter-spacing: -0.12px;
    color: #9b9b9d;
    max-width: 196px;
    margin: 20px auto 50px;

    a {
      color: #9f97bf;
      text-decoration: underline;
    }

    span {
      color: #F45F09;
    }
  }
}
