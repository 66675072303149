@import "branding";

input[type=text], input[type=password], input[type=email] {
  color: $app-dark-tone;
  height: 5rem;
  border: 1px solid #e0e0e0;
  width: 100%;
  font-weight: 500;
  font-size: 1.8rem;
  padding: 1.5rem 2rem;
  border-radius: 0;
  -webkit-appearance: none;
  max-width: 424px;

  &.rum-error-field {
    border: solid 1px #e45151;
    color: #e45151;
  }

  @media (min-width: $bp-smallest) {
    &::placeholder {
      padding-top: 4px;
    }
  }

  @media (max-width: $bp-smallest) {
    border-left: none;
    border-right: none;
  }

  &::placeholder {
    font-size: 1.6rem;
    letter-spacing: 0.5px;
  }

  &:focus {
    //outline: none;
  }
}

form {
  width: 100%;
  max-width: 424px;
}
