.side-menu-toggle {
  color: white;
  background-color: transparent;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  text-transform: initial;
  text-decoration: none;
  border: 1px solid transparent;
  white-space: nowrap;
  width: 40px;

  &:hover {
    background-color: rgba(135, 135, 135, 0.1);
  }

  i {
    font-size: 30px;
  }
}

.header-help {
  background-color: transparent;
  border: 1px solid transparent;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;
  font-size: 1.6rem;
  font-weight: 400;

  i {
    font-size: 1.6rem;
    margin-right: 0.25em;
  }
}

.nav-header {
  position: fixed;
  height: 60px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}
