.password-meter-background {
  width: 261px;
  position: relative;

  &-content {
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
