@import '../../assets/styles/branding';

/* The Modal (background) */
.rum-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content */
.rum-modal-content {
  position: relative;
  margin: auto;
  padding: 0;
  overflow: hidden;
  max-width: 80%;
  width: 420px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  background-color: $app-background-color;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: $ds-text-light-color;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.rum-modal-header {
  padding: 28px 20px 19px;
  background-color: white;
  color: $ds-text-light-color;
}

.rum-modal-body {
  padding: 0 19px;
  display: flex;
  flex-direction: column;
}

.rum-modal-footer {
  padding: 24px 19px;
  background-color: white;
  text-align: center;
  color: $ds-text-light-color;

  button {
    margin: 0 auto;
    width: 14rem;
  }
}

.rum-modal-text-title {
  font-size: 1.4rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: $app-dark-tone;
}

.rum-modal-text-content {
  font-family: $font-standard;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #9a9a9a;
}

.rum-modal-btn-accent {
  font-family: $font-standard;
  font-size: 1.4rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: center;
  display: block;
  width: 25rem;
  height: 4.8rem;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  border-radius: 11px;
  background-color: transparent;
  color: $app-dark-tone;
  margin: auto;
}

.rum-modal-image-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
}
