@import '../../assets/styles/branding';

.card {
  background-color: $app-background-color;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: $bp-medium) {
    margin: 10px 20px;
    padding: 10px 20px;
    flex-basis: 100%;
    justify-content: center;
  }

  @media screen and (min-width: 901px) {
    margin: 20px 20px;
    padding: 20px 20px;
  }

  .card-content {
    .card-icon {
      color: $app-accent-color;
    }

    .card-headline {
      color: $ds-dusty-gray-color;
      font-size: 13px;
      line-height: 1.23;

      &-italicized {
        font-style: italic;
      }
    }

    .card-name {
      color: $app-dark-tone;
      font-weight: 700;
      font-size: 14px;
    }

    @media screen and (max-width: $bp-medium) {
      display: flex;
      margin-bottom: 0;
      align-items: center;

      .card-logo {
        width: 60px;
        height: 60px;
        margin-right: 15px;
        border-radius: 50%;
      }

      .card-description {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .card-name {
        margin-bottom: 5px;
      }

      .card-link {
        display: none;
      }
    }

    @media screen and (min-width: 901px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 250px;
      height: 220px;
      min-width: 300px;
      margin-bottom: 16px;

      .card-icon {
        position: absolute;
        top: 15px;
        right: 15px;
      }

      .card-logo {
        width: 80px;
        height: 80px;
        margin: 30px 20px 20px;
        border-radius: 50%;
      }

      .card-description {
        margin-bottom: 20px;
        height: 50px;
        text-align: center;
      }

      .card-name {
        margin-top: 0;
      }

      .card-link {
        font-size: 1.4rem;
        color: $app-accent-color;
        text-decoration: underline;
      }
    }
  }
}
