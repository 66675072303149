@import '../../assets/styles/branding';

.private-space {
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: row;

  .private-space-wrapper {
    flex: 1;
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;

    .private-space-content {
      flex-grow: 1;
      margin-left: 325px;
      background-color: $app-background-color-dashboard;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
      overflow: auto;

      @media (max-width: 730px) {
        margin-left: 0;
      }

      // This is a hack to center the action buttons when the side nav is opened
      .feed-user-actions {
        left: 325px;
      }

      &.side-menu-hidden {
        margin-left: 0;

        // This is a hack to reset the action buttons left to 0 when the side nav is closed
        .feed-user-actions {
          left: 0;
        }
      }
    }
  }
}
