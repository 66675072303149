@import "branding";

h1, h2, h3, h4, h5 {
  color: $app-dark-tone;
  font-weight: 500;
  letter-spacing: 0.17px;
}

h1 {
  font-size: 3.2rem;
}
h2 {
  font-size: 2.8rem;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
}
h3 {
  font-size: 2.4rem;
}

.text-medium {
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9a9a9a;
  max-width: 42.4rem;

  @media only screen and (max-width: $bp-smallest) {
    max-width: 90%;
  }

  a {
    text-decoration: none;
    color: $app-accent-color;
    font-weight: 500;
  }
}

.text-small {
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #9a9a9a;
  max-width: 42.4rem;

  @media only screen and (max-width: $bp-smallest) {
    max-width: 90%;
  }

  a {
    text-decoration: none;
    color: $app-accent-color;
    font-weight: 500;
  }
}

.text-align-left {
  text-align: left;
}