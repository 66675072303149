@import '../../assets/styles/branding';

.data-debit-origin {
  display: flex;
  align-items: center;
  justify-content: center;

  .card-img-wrapper {
    margin-right: 20px;

    img {
      border-radius: 50%;
    }
  }
}

.debit-valitity-interval {
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  padding-top: 30px;
  color: $app-dark-tone;

  i {
    vertical-align: bottom;
    margin: 0 10px;
  }
}

.data-debit-privacy-policy {
  display: block;
  margin-top: 30px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  color: $app-accent-color;
}
