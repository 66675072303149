@import '../../assets/styles/branding';

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $app-background-color-dashboard;

  .profile-header {
    width: 100%;

    p {
      font-size: 14px;
      color: #9b9b9b;
      margin: 0;
      text-align: center;
      padding: 10px 10px 40px;
    }

    &-box {
      margin: 80px auto;
      width: 90%;
      position: relative;
      max-width: 650px;
      border-radius: 8px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      background-color: $app-background-color;
    }

    &-photo-container {
      width: 100px;
      height: 100px;
      margin: auto;
      border-radius: 50%;
      border: 2px solid #fff;
      position: relative;
      top: -50px;

      svg, img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      img {
        object-fit: cover;
      }
    }

    &-hat-domain-wrapper {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 15px;
      text-align: center;

      .hat-name, .hat-domain {
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: 0.4px;
        text-align: center;
      }

      .hat-name {
        color: $app-dark-tone;
        font-size: 20px;
        font-weight: bold;
      }

      .hat-domain {
        color: $ds-dusty-gray-color;
        font-size: 14px;
      }
    }

    &-social-links {
      a {
        margin: 0 7px;

        img {
          top: 0;
          border: none;
          border-radius: 50%;
          background: $app-background-color-dashboard;

          @media (max-width: 500px) {
            width: 25px;
            height: 25px;
          }
          @media (min-width: 501px) {
            width: 35px;
            height: 35px;
          }
        }
      }
    }

    &-link-to-public {
      position: absolute;
      right: 15px;
      top: 15px;

      a {
        color: $button-accent-color;
      }
    }
  }
}

.profile-details-section {
  margin: 50px auto;
  background-color: $app-background-color-dashboard;
  border-radius: 5px;
  box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
  width: 500px;
  max-width: 90%;
}

.profile-details-content {
  padding: 40px 30px 50px 30px;
  background-color: $app-background-color;
}

.profile-details-heading {
  background-color: $app-accent-color;
  font-size: 18px;
  padding: 1.1rem 2.2rem;
  color: $ds-text-light-color;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}


.upload-logo-placeholder {
  height: 100px;
  width: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: transparent;
  border: solid 1px transparent;
  cursor: pointer;
  margin-right: 50px;
  position: relative;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #00a3ff;

  .upload-logo-state {
    position: absolute;
    top: 4px;
    right: 4px;
    display: inline-flex;
    padding: 3px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.upload-logo-dropzone {
  display: inline-flex;
  flex-direction: column;
  outline: none;
}

.upload-failed {
  color: #E45151;
  font-size: 16px;
}

.upload-success {
  color: #7DB774;
  font-size: 16px;
}

.logo-uploading {
  color: #ABABAB;
  font-size: 16px;
}

.upload-logo-image-preview {
  height: 100%;
  width: 100%;
}

.upload-logo-container {
  display: inline-block;
}
