@import '../../assets/styles/branding';

.ds-information-details {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;

  &-header {
    color: $app-dark-tone;
    font-weight: 700;
    font-size: 1.3rem;
    height: 30px;
    text-transform: uppercase;
    margin: 40px auto;
    min-width: 100px;
    text-align: center;
    border-bottom: 2px solid $app-dark-tone;
  }

  &-section {
    max-width: 900px;
    width: 100%;
    margin: 15px auto;
    background-color: white;
    border-radius: 4px;
    padding: 16px;

    h5 {
      font-weight: 700;
      color: $app-dark-tone;
      font-size: 1.4rem;
      margin-bottom: 10px;
    }

    &-description {
      color: $ds-dusty-gray-color;
      font-size: 1.3rem;
      line-height: 1.54;
      white-space: pre-wrap;
    }

    &-screenshots {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      overflow-y: hidden;

      img {
        width: 200px;
        height: 400px;
        border-radius: 4px;
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }

    &-requirements {
      margin-left: 15px;
      .requirements-title {
        font-weight: 700;
        color: $app-dark-tone;
        font-size: 1.3rem;
        margin-top: 10px;
      }
      ul {
        li {
          margin-left: 25px;
          font-size: 1.3rem;
        }
      }
    }
  }
}
