.profile-dropdown {
  border-left: 1px solid rgba(135,135,135,.5);
  padding: 0 16px;

  &-toggle {
    background-color: transparent;
    border: 1px solid transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-arrow {
    color: white;
    font-size: 28px;
  }
}

.profile-dropdown-overlay {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  top: 58px;
  right: 46px;
  color: black;
  width: 320px;
  border-radius: 4px;
  outline: 0;
  background-color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);

  @media (max-width: 500px) {
    right: 8px;
  }
}
.user-photo {
  flex: 1 1 auto;
  cursor: pointer;
  margin-right: 1em;

  img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
  }

  .notlogged {
    margin-top: -13px;
  }
}

.welcome {
  color: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: .5px;
  line-height: 1.1;
  padding-right: 1em;
  text-align: left;

  span {
    font-weight: 200;
    font-size: 12px;
    color: #eee;
    letter-spacing: .5px;
    opacity: .7;
  }

  @media screen and (max-width: 510px) {
    display: none;
  }
}

.dropdown-item {
  cursor: pointer;
  padding: 10px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #9a9a9a;
  opacity: 0.9;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  letter-spacing: 0.4px;
  height: 50px;
  border: 1px solid transparent;
  background-color: white;
  font-weight: 400;

  p {
    margin-bottom: 0;
  }

  i {
    color: rgba(135, 135, 135, 0.5);
  }

  &:hover {
    color: #6297b1;
    text-decoration: none;
    background-color: #E8E8E8;

    i {
      color: #6297b1;
    }
  }
}

.accountDetails {
  display: flex;
  align-items: center;
  padding: 18px 25px 18px;
  width: 100%;
  cursor: auto;

  h6 {
    color: #4a556b;
    margin-bottom: 0;
    display: block;
    font-size: 16px;
    letter-spacing: 0.7px;

    span {
      color: #4a556b;
      font-size: 10px;
      letter-spacing: 0.7px;
      font-weight: 400;
    }
  }

  img {
    width: 70px;
    height: 70px;
    margin-top: 0;
    margin-right: 15px;
  }
}

.accountUsage {
  padding: 0 25px;
  cursor: auto;

  .app-details-header-headline {
    margin: 5px 0;
    font-size: 14px;
    color: #9a9a9a;
    line-height: 1.38;
  }

  .bar-container {
    height: 5px;
    border-radius: 5px;
    background-color: #DEE9EE;
    display: block;
    width: 100%;
    padding: 0;

    .bar {
      background-color: #6297b1;
      display: block;
      height: 100%;
      border-radius: 5px;
      width: 50%;
    }
  }

  h6 {
    color: #9a9a9a;
    font-size: 10px;
    letter-spacing: 0;
    font-family: Roboto, sans-serif;
    margin-bottom: 25px;
    opacity: 0.9;
    font-weight: 200;
  }
}

.dropdown-divider {
  border-bottom: 1px solid rgba(135,135,135,.1);
  padding: 0;
  min-width: 280px;
  height: auto;
}
