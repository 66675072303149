.loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
}

.loading-spinner,
.loading-spinner::before,
.loading-spinner::after {
  border: 2px solid rgba(74, 85, 107, 0.6);
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  border-radius: 999px;
}

.loading-spinner {
  margin: 20px auto;
  height: 75px;
  width: 75px;
  mask-border: linear-gradient(rgba(74, 85, 107, 1), rgba(74, 85, 107, 0.1) 50%);
  -webkit-animation: animation-rotate 1250ms linear infinite;
  -moz-animation: animation-rotate 1250ms linear infinite;
  -o-animation: animation-rotate 1250ms linear infinite;
  animation: animation-rotate 1250ms linear infinite;
}

.loading-text {
  color: rgba(74, 85, 107, 0.6);
  font-size: 14px;
  font-variant: small-caps;
  text-align: center;
}

@-webkit-keyframes animation-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes animation-rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes animation-rotate {
  100% {
    -o-transform: rotate(360deg);
  }
}

@keyframes animation-rotate {
  100% {
    transform: rotate(360deg);
  }
}


.ds-loading-animation {
  max-width: 500px;
  width: 100%;
  position: relative;
}

#ds-loading-d {
  z-index: -1;
  position: absolute;
  opacity: 0;
  animation: ds-loading-animation 600ms ease-in-out infinite;
  -webkit-animation: ds-loading-animation 2500ms ease-in-out infinite;
  -moz-animation: ds-loading-animation 600ms ease-in-out infinite;
  -o-animation: ds-loading-animation 600ms ease-in-out infinite;
  animation-delay: 2000ms;
}

#ds-loading-d-shadow-1 {
  z-index: -1;
  position: absolute;
  animation: ds-loading-animation 600ms ease-in-out infinite;
  -webkit-animation: ds-loading-animation-1 1700ms ease-in-out infinite;
  -moz-animation: ds-loading-animation 600ms ease-in-out infinite;
  -o-animation: ds-loading-animation 600ms ease-in-out infinite;
}

#ds-loading-d-shadow-2 {
  z-index: -1;
  position: absolute;
  animation: ds-loading-animation 600ms ease-in-out infinite;
  -webkit-animation: ds-loading-animation-2 1500ms ease-in-out infinite;
  -moz-animation: ds-loading-animation 600ms ease-in-out infinite;
  -o-animation: ds-loading-animation 600ms ease-in-out infinite;
}

#ds-loading-d-shadow-3 {
  z-index: -1;
  position: absolute;
  animation: ds-loading-animation 600ms ease-in-out infinite;
  -webkit-animation: ds-loading-animation-3 1100ms ease-in-out infinite;
  -moz-animation: ds-loading-animation 600ms ease-in-out infinite;
  -o-animation: ds-loading-animation 600ms ease-in-out infinite;
}

#ds-loading-sticky {
  left: 210px;
  position: absolute;
}

@keyframes ds-loading-animation {
  0% {
    opacity: 0;
    right: 20%;
  }

  10% {
    opacity: 0.4;
    right: 50%;
  }

  20% {
    opacity: 0;
    right: 80%;
  }
  100% {
    opacity: 0;
    right: 0;
  }
}

@keyframes ds-loading-animation-1 {
  0% {
    opacity: 0;
    right: 10%;
  }

  10% {
    opacity: 0.8;
    right: 50%;
  }

  20% {
    opacity: 0;
    right: 90%;
  }

  100% {
    opacity: 0;
    right: 0;
  }
}

@keyframes ds-loading-animation-2 {
  0% {
    opacity: 0;
    right: 40%;
  }

  10% {
    opacity: 0.8;
    right: 50%;
  }

  20% {
    opacity: 0;
    right: 60%;
  }

  100% {
    opacity: 0;
    right: 0;
  }
}

@keyframes ds-loading-animation-3 {
  0% {
    opacity: 0;
    right: 0;
  }

  10% {
    opacity: 0.8;
    right: 50%;
  }

  20% {
    opacity: 0;
    right: 100%;
  }

  100% {
    opacity: 0;
    right: 0;
  }
}
