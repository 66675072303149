.flex-row-wrapper {
  display: flex;
  flex-direction: row;
}

.flex-column-wrapper {
  display: flex;
  flex-direction: column;
}

.flex-spacer-small {
  flex-grow: 1;
}

.flex-spacer-large {
  flex-grow: 2;
}

.flex-content-center {
  justify-content: center;
}

.flex-align-items-center {
  align-items: center;
}