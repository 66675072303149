@import "branding";
@import 'material-icons/iconfont/material-icons.scss';

* {
  margin: 0;
  padding: 0;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%

  @media only screen and (max-width: $bp-large) {
    font-size: 62.5%;
  }
}

body {
  font-family: $font-standard;
  font-weight: 400;
  line-height: 1.6;
  background-color: $app-background-color;
}

html, body, #root, #root > div {
  min-height: 100vh;
}
