@import "branding";

button {
  font-family: $font-standard;
  font-weight: 500;
  cursor: pointer;
}

button:focus {
  outline: 1px solid #afafaf;
  outline-offset: 2px;
}

button::-moz-focus-inner {
  border: 0;
}

.btn {
  display: block;
  font-weight: normal;
  width: 25rem;
  height: 4.8rem;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  border-radius: 4px;
  font-size: 1.4rem;
}

.btn-accent {
  background-color: $button-accent-color;
  color: $button-accent-text-color;

  &:disabled {
    background-color: $button-accent-disabled-color;
    cursor: default;
  }
}

.btn-transparent {
  background-color: $button-transparent-color;
  color: $button-accent-color;
}

.btn-transparent-grey {
  background-color: $button-transparent-color;
  color: #acacac;
  font-size: 1.6rem;
  text-transform: initial;
  text-decoration: none;
  letter-spacing: 0.15px;
}

.link-button {
  border: none;
  background: transparent;
  color: $app-accent-color;
  font-weight: 700;
  cursor: pointer;
  letter-spacing: 0.4px;
  font-size: 1.4rem;
}

a {
  text-decoration: none;
}

a:focus {
  outline: 1px solid #afafaf;
  outline-offset: 2px;
}

